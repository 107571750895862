.splash-header-container {
  font-size: 40px;
  background-color: var(--secondary-color);
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.splash-header-icon {
  padding-right: 10px;
  align-self: center;
}

.splash-header-text {
  align-self: center;
}