.dr-home-container {
  display: flex;
  flex-direction: row;
  row-gap: 10px;
  column-gap: 10px;
  flex-wrap: wrap;
  overflow: auto;
  padding: 16px;
}

.dr-home-weekly-container {
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: var(--column-width);
  max-height: 100%;
  overflow-y: scroll;
}

.dr-home-other-results-container {
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: var(--column-width);
  max-height: 100%;
  overflow-y: scroll;
}