.dr-runners-container {
  max-width: 100%;
  font-size: 1.6rem;
  padding: 16px;
  margin-top: 0;
}

.dr-runner-title {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  margin: 16px;
}

.dr-runner-pbs {
  padding: 16px;
}