@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700");

:root {
  /* Material palette https://material-ui.com/customization/color/ */
  /* Icons at: https://icons8.com/icons */
  --primary-color: #1976d2; 
  --secondary-color: #ED1C24; 
  --plain-color: black;
  --main-bg-color: #e7e7e7;
  --paper-color: white;
  --light-text-color: white;
  --group-color: #f0f0f0;
  --separator-color: #999;
  --text-highlight: orange;
  --text-highlight-2: #2e7d32;

  --title-color: var(--primary-color);
  
  --menu-background: var(--primary-color);
  --menu-unselected: #bbb;
  --menu-selected: var(--light-text-color);

  --table-select-color: var(--secondary-color);
  --table-bg-color: white;
  --table-header-color: #26a69a;
  --table-alt-header-color: orange;
  --table-border-color: #e7e7e7;
  --disabled-text-color: #999;

  font-family: "MaisonNeue","Segoe UI","Helvetica Neue";
}

/**********/
/* LAYOUT */
/**********/

/* 1 column narrow */
/* Default font for small screen */
/* Mobile phone in vertical position */
html {
  font-size: 62.5%; /* 10px trick */
  font-size: 2.5vmin;
  --column-width: 90%;
  --field-width: 90%;
}

.icon {
  width: auto;
  height: 32px;
}  

/* 1 column wide */
/* Mobile phone in horizontal position */
@media (min-width: 600px)
{
  html
  {
    --field-width: 45%;
  }
}

/* 2 Columns narrow */
/* Wide (but not necessarily high) */
/* It could be a tablet in horizontal position */
@media (min-width: 900px)
{
  html
  {
    --column-width: 45%;
    --field-width: 90%;
  }
}

/* Medium width */
@media (min-width: 600px) and (min-height: 600px)
{
  html
  {
    font-size: 1.5vmin;
  }
  
  .icon {
    width: auto;
    height: 32px;
  }  
}

/* Medium-large width */
@media (min-width: 900px) and (min-height: 900px)
{
  html
  {
    font-size: 1.2vmin;
  }
  
  .icon {
    width: auto;
    height: 36px;
  }  
}

/* 2 columns wide */
/* Large screen */
@media (min-width: 1200px) and (min-height: 1200px)
{
  html
  {
    font-size: 1.2vmin;
    --field-width: 45%;
  }
  
  .icon {
    width: auto;
    height: 36px;
  }  
}

body
{
  background-color: var(--main-bg-color);
  color: var(--title-color);
  font-size: 1.4rem;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

.meetingcol {
  white-space: normal;
}

.shortnamecol {
  white-space: normal;
}

.namecol {
  white-space: normal;
}

.descrcol {
  max-width: 50ch;
  word-wrap: break-word;
  white-space: normal;  /* This allows wrapping to happen */
}

.pbcol {
  max-width: 20ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Small Screen configuration */
@media (max-width: 600px)
{
  .meetingcol {
    min-width: 10ch;
    max-width: 20ch;
    white-space: normal;
  }

  .descrcol {
    max-width: 30ch;
    word-wrap: break-word;
    white-space: normal;  /* This allows wrapping to happen */
  }

  .shortnamecol {
    max-width: 15ch;
    white-space: normal;
  }

  .namecol {
    min-width: 15ch;
    max-width: 20ch;
    white-space: normal;
  }
}

.gridcontainer {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  grid-gap: 3%;
  background-color: var(--main-bg-color);
  color: var(--title-color);
}

.griditem {
  border-radius: 5px;
  background-color: white;
  border: 1px solid var(--table-border-color);
  padding: 5px;
  font-size: 1.2rem;
  cursor: pointer;
}

.group {
  font-size: 1.6rem;
  background-color: var(--group-color);
  color: var(--title-color);
  text-align: left;
  margin: 3px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.main {
  background-color: var(--main-bg-color);
}

.wrapper {
  white-space: nowrap;
  overflow-y: scroll;
  overflow-x: visible;
}

.limitcontainer {
  width: 100%;
}

.link {
  cursor: pointer;
}

.cardset {
  /* background-color: var(--paper-color); */
  width: 100%;
  cursor: pointer;
}

.paper {
  background-color: var(--paper-color);
  color: var(--primary-color);
  border-radius: 10px;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--separator-color);
  margin: 3px;
}

.gridcard {
  display: grid;
  grid-gap: 5px;
  cursor: pointer;
 }

.flexcard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  row-gap: 5px;
  column-gap: 5px;
}

.card {
  background-color: var(--paper-color);
  color: var(--primary-color);
  padding: 5px;
  border-bottom: 1px solid var(--table-border-color);
}

.cardselected {
  background-color: var(--paper-color);
  color: var(--primary-color);
  padding: 5px;
  border-style: ridge;
}

.view {
  position: relative;
  overflow-y: scroll;
  overflow-x: visible;
  padding: 5px;
}

.tile {
  padding: 5px;
  border-radius: 10px;
  overflow-x: scroll;
}

/**********/
/* TABLES */
/**********/

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.plaintable {
  width: 100%;
}

.detailstable tr {
  background-color: white;
  text-align: center;
  font-size: 1rem;
}

.bicolortable {
  width: 100%;
  font-size: 1.2rem;
}

.bicolortable tbody tr:nth-child(odd) {
  background-color: var(--table-border-color);
}

.bicolortable tbody tr:nth-child(even) {
  background-color: var(--table-bg-color);
}

.fixedtable {
  table-layout: fixed;
}

labelContainer {
  padding: 0,
}

.borderright {
  border-right: solid 1px var(--table-header-color);
}

.disabledcell {
  color: var(--disabled-text-color);  
}

tr.selectedrow td {
  border-bottom: solid 1px var(--text-select-color);
  color: var(--table-select-color);
}

.iconcell {
  text-align: left;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
}

.first-col {
  width: 4em;
  min-width: 4em;
  max-width: 4em;
  left: 0px;
}

.first-large-col {
  width: 12em;
  min-width: 12em;
  max-width: 12em;
  left: 0px;
  white-space: normal;
  word-wrap: break-word;
}

.second-col {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  left: 5em;
}

.titlerow {
  font-size: 1.8rem;
  font-weight: bold;
  background-color: var(--table-border-color);
  color: var(--title-color);
  text-align: left;
}

td.vertical {
  writing-mode: vertical-rl;
  text-orientation: sideways;
}

/****************************/
/* Generic Table attributes */
/****************************/

table {
  border-collapse: collapse;
  background-color: var(--table-bg-color);
 /* margin: 10px 10px 10px 10px; */
}

tr {
  box-sizing: border-box;
}

th {
  color: white;
  background-color: var(--table-header-color);
  border-bottom: solid 1px var(--table-border-color);
  top: 0;
  text-align: center;
  padding: 5px;
}

td {
  color: var(--primary-color);
  border-bottom: solid 1px var(--table-border-color);
  border-left: 0;
  border-right: 0;
  border-top: 0;
  white-space: nowrap;
  text-align: right;
  padding: 5px;
}

/*********/
/* FORMS */
/*********/
.form {
  display: block;
  padding-bottom: 10px;
}

.form-group {
  display: inline;
  padding-right: 10px;
}

.form-label {
  font-weight: bold;
}

.form-input {
  border: 0 0 1px 0 solid;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid;
  margin-left: 10px;
  background-color: var(--main-bg-color)
}

.form-input::placeholder {
  font-style: italic;
}

/* Generic Elements */

.largetext {
  font-size: 1.8rem;
  font-weight: bold;
}

p {
  font-size: 1.4rem;
}

a {
  font-size: 1.4rem;
}

h1 {
  font-size: 2.4rem;
  font-weight: bold;
  margin-left: 2%;
  margin-right: 2%;
}

h2 {
  font-size: 1.8rem;
  font-weight: normal;
}

h3 {
  font-size: 1.4rem;
  font-weight: normal;
}

h4 {
  font-size: 1.2rem;
  text-align: center;
}

li {
  font-size: 1.4;
}
